// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-weknow-gatsby-theme-drupal-boina-src-components-templates-article-index-js": () => import("/opt/build/repo/node_modules/@weknow/gatsby-theme-drupal-boina/src/components/templates/article/index.js" /* webpackChunkName: "component---node-modules-weknow-gatsby-theme-drupal-boina-src-components-templates-article-index-js" */),
  "component---node-modules-weknow-gatsby-theme-drupal-boina-src-components-templates-page-index-js": () => import("/opt/build/repo/node_modules/@weknow/gatsby-theme-drupal-boina/src/components/templates/page/index.js" /* webpackChunkName: "component---node-modules-weknow-gatsby-theme-drupal-boina-src-components-templates-page-index-js" */),
  "component---node-modules-weknow-gatsby-theme-drupal-boina-src-components-templates-tags-index-js": () => import("/opt/build/repo/node_modules/@weknow/gatsby-theme-drupal-boina/src/components/templates/tags/index.js" /* webpackChunkName: "component---node-modules-weknow-gatsby-theme-drupal-boina-src-components-templates-tags-index-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---node-modules-weknow-gatsby-theme-drupal-boina-src-pages-404-js": () => import("/opt/build/repo/node_modules/@weknow/gatsby-theme-drupal-boina/src/pages/404.js" /* webpackChunkName: "component---node-modules-weknow-gatsby-theme-drupal-boina-src-pages-404-js" */)
}

