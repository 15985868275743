module.exports = [{
      plugin: require('/opt/build/repo/node_modules/@weknow/gatsby-remark-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"withWebp":true,"maxWidth":790,"linkImagesToOriginal":false,"quality":70},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-17944133-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Enzo - WeKnow Blog","short_name":"enzo's Weknow Blog","start_url":"/","background_color":"#eaeaea","theme_color":"#644b78","display":"minimal-ui","icon":"/opt/build/repo/node_modules/@weknow/gatsby-theme-drupal-boina/static/icon.png"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/node_modules/@weknow/gatsby-theme-drupal-boina/gatsby-browser.js'),
      options: {"plugins":[],"root":"/opt/build/repo"},
    }]
